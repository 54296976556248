
import axios from 'axios';
export default {
    async purchasesReport(columns) {
        return await axios.get(`reports/purchases?${columns}`)
    }, 
    async expensesReport(columns) {
        return await axios.get(`reports/expenses?${columns}`)
    },
    async incomesReport(columns) {
        return await axios.get(`reports/incomes?${columns}`)
    },
    async accountsWithTotalBalances(columns) {
        return await axios.get(`reports/accountsWithTotalBalances?${columns}`)
    },
    async accountBalance(columns) {
        return await axios.get(`reports/accountBalance?${columns}`)
    },
    async accountDetails(columns) {
        return await axios.get(`reports/accountDetails?${columns}`)
    },
    async loansReport(columns) {
        return await axios.get(`reports/loans?${columns}`)
    },
    async qistReport(columns) {
        return await axios.get(`reports/qist?${columns}`)
    },
    async invoicesReport(columns) {
        return await axios.get(`reports/invoices?${columns}`)
    },
    async invoiceItemsReport(columns) {
        return await axios.get(`reports/invoiceItems?${columns}`)
    },
    async invoiceItemsTotalReport(columns) {
        return await axios.get(`reports/invoiceItemsTotal?${columns}`)
    },
    async customersLoansReport(columns) {
        return await axios.get(`reports/customersLoans?${columns}`)
    },
    async inventoryReport(columns) {
        return await axios.get(`reports/inventory?${columns}`)
    },
    async totalBalanceReport(columns) {
        return await axios.get(`reports/totalBalance?${columns}`)
    }
}

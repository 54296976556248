<template>
	<div class="pa-3">
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-layout row wrap v-else>
			<v-flex xs12>
				{{ $store.getters.language.data.total }}: {{ (total).toLocaleString() }}

				<table style="width: 100%;" class="text-center" v-if="type == 'incomes'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.incomes.income_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.incomes.income_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.incomes.money_move_type_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.incomes.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.income_id">
							<td> {{ index + 1 }} </td>
							<td> {{ (item.income_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ new Date(item.income_date).toISOString().split('T')[0] }} </td>
							<td> {{ item.money_move_type_name }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'expenses'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.expenses.expense_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.expenses.expense_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.expenses.money_move_type_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.expenses.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.expense_id">
							<td> {{ index + 1 }} </td>
							<td> {{ (item.expense_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ new Date(item.expense_date).toISOString().split('T')[0] }} </td>
							<td> {{ item.money_move_type_name }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'qist'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.invoice_id }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.qist_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.paid_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.qist_status }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.qist_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.account_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.qist.customer_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.qist_id">
							<td> {{ index + 1 }} </td>
							<td>
								<a @click.prevent="openInvoice(item.invoice_id)"> {{ item.invoice_id }} </a>
							</td>
							<td> {{ new Date(item.qist_date).toISOString().split('T')[0] }} </td>
							<td> {{ new Date(item.paid_date).toISOString().split('T')[0] }} </td>
							<td> {{ item.qist_status }} </td>
							<td> {{ (item.qist_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ item.account_name }} </td>
							<td> {{ item.customer_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'invoices'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.invoices.invoice_id }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoices.invoice_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoices.customer_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.invoices.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.invoice_id">
							<td> {{ index + 1 }} </td>
							<td>
								<a @click.prevent="openInvoice(item.invoice_id)"> {{ item.invoice_id }} </a>
							</td>
							<td> {{ new Date(item.invoice_date).toISOString().split('T')[0] }} </td>
							<td> {{ item.customer_name }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'loans'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.loans.loan_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.loans.customer_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.loans.loan_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.loans.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.loan_id">
							<td> {{ index + 1 }} </td>
							<td> {{ new Date(item.loan_date).toISOString().split('T')[0] }} </td>
							<td> {{ item.customer_name }} </td>
							<td> {{ (item.loan_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'sended'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.account_transfer_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.account_transfer_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.receiver }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.account_transfer_id">
							<td> {{ index + 1 }} </td>
							<td> {{ new Date(item.account_transfer_date).toISOString().split('T')[0] }} </td>
							<td> {{ (item.account_transfer_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ item.receiver_name }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'received'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.account_transfer_date }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.account_transfer_amount }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.sender }} </th>
							<th class="py-1"> {{ $store.getters.language.data.account_transfers.user_name }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.account_transfer_id">
							<td> {{ index + 1 }} </td>
							<td> {{ new Date(item.account_transfer_date).toISOString().split('T')[0] }} </td>
							<td> {{ (item.account_transfer_amount).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ item.sender_name }} </td>
							<td> {{ item.user_name }} </td>
						</tr>
					</tbody>
				</table>

				<table style="width: 100%;" class="text-center" v-if="type == 'purchases'">
					<thead>
						<tr>
							<th class="py-1"> # </th>
							<th class="py-1"> {{ $store.getters.language.data.items.item_name }} </th>
							<th class="py-1"> {{ $store.getters.language.data.purchases.purchase_qty }} </th>
							<th class="py-1"> {{ $store.getters.language.data.purchases.purchase_price }} </th>
							<th class="py-1"> {{ $store.getters.language.data.purchases.purchase_date }} </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in rows" :key="item.purchase_id">
							<td> {{ index + 1 }} </td>
							
                            <td> {{ item.item_name }} </td> 
                            <td> {{ item.purchase_qty }} </td> 
							<td> {{ ((item.purchase_total_price) ||0).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
							<td> {{ new Date(item.purchase_date).toISOString().split('T')[0] }} </td>
						</tr>
					</tbody>
				</table>

			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import requests from '../../../requests/reports.request'
	export default {
		data() {
			return {
				loading: true,
				account_id: null,
				rows: [],
				type: null,
				total: 0
			}
		},
		mounted() {
			this.loading = true
			this.account_id = this.$route.params.account_id
			this.type = this.$route.params.type
			requests.accountDetails(`store_id=${this.$store.getters.store.store_id}&account_id=${this.account_id}&type=${this.type}`).then(r => {
				if (this.type == 'incomes') {
					this.rows = r.data.rows.incomes.rows;
					this.total = r.data.rows.incomes.total;
				} else if (this.type == 'expenses') {
					this.rows = r.data.rows.expenses.rows;
					this.total = r.data.rows.expenses.total;
				} else if (this.type == 'qist') {
					this.rows = r.data.rows.qist.rows;
					this.total = r.data.rows.qist.total;
				} else if (this.type == 'invoices') {
					this.rows = r.data.rows.invoices.rows;
					this.total = r.data.rows.invoices.total;
				} else if (this.type == 'loans') {
					this.rows = r.data.rows.loans.rows;
					this.total = r.data.rows.loans.total;
				} else if (this.type == 'sended') {
					this.rows = r.data.rows.sended.rows;
					this.total = r.data.rows.sended.total;
				} else if (this.type == 'received') {
					this.rows = r.data.rows.received.rows;
					this.total = r.data.rows.received.total;
				}
				else if (this.type == 'purchases') {
					this.rows = r.data.rows.purchases.rows;
					this.total = r.data.rows.purchases.total;
				}
				this.loading = false
			})
		},
		methods: {
			openInvoice(id) {
				window.open(`/pos/${id}`, '_blank')
			},
		}
	}
</script>


<style scoped>
	table {
		border: 1px solid;
		border-collapse: collapse;
	}
	tr {
		border: 1px solid;
	}
	td,
	th {
		border: 1px solid;
	}
</style>
